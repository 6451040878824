import * as React from "react";
import { inject, observer } from "mobx-react";
import { FullBackgroundRegionProps } from "./typings";
import { withLocalization } from "@shared-ui/localization-context";
import { logger } from "bernie-core";
import { ViewMedium, Viewport, ViewSmall } from "@shared-ui/viewport-context";
import { EGDSFigure, EGDSFigureAspectRatioType } from "@egds/react-core/images";
import { EGDSLayoutConditionalGridTrack, EGDSLayoutGrid, EGDSLayoutGridItem } from "@egds/react-core/layout-grid";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { hasRenderableChildren } from "src/stores/ExperienceTemplateStore/shouldRender";
import { RegionChildren } from "src/utils/RegionUtils";

@withLocalization
@logger("FullBackgroundRegion")
@observer
@inject("analytics", "flexModuleModelStore")
export class FullBackgroundRegion extends React.Component<FullBackgroundRegionProps> {
  public render() {
    const { templateComponent, flexModuleModelStore, blossomComponent } = this.props;

    if (!blossomComponent) {
      return null;
    }

    if (!templateComponent.config || !hasRenderableChildren(templateComponent, flexModuleModelStore)) {
      return null;
    }

    const { width, backgroundImage } = templateComponent.config;

    const mainGridColumns: EGDSLayoutConditionalGridTrack = { small: 2, medium: 12, large: 12 };
    const regionWidthToGridMap: any = {
      "25": { colStart: { small: 1, medium: 6, large: 6 }, colEnd: { small: 3, medium: 8, large: 8 } },
      "33": { colStart: { small: 1, medium: 4, large: 5 }, colEnd: { small: 3, medium: 10, large: 9 } },
      "50": { colStart: { small: 1, medium: 4, large: 4 }, colEnd: { small: 3, medium: 10, large: 10 } },
      "66": { colStart: { small: 1, medium: 3, large: 3 }, colEnd: { small: 3, medium: 11, large: 11 } },
      "75": { colStart: { small: 1, medium: 2, large: 2 }, colEnd: { small: 3, medium: 12, large: 12 } },
      "100": { colSpan: 12 },
    };
    return (
      <div data-testid="main-region">
        <Viewport>
          <ViewSmall>
            <EGDSSpacing padding={{ inline: "two" }}>
              <div>{<RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />}</div>
            </EGDSSpacing>
          </ViewSmall>
          <ViewMedium>
            <EGDSFigure
              src={backgroundImage || ""}
              imageFit="cover"
              ratio={EGDSFigureAspectRatioType.R4_1}
              data-testid="heroImageFigure"
            >
              <EGDSLayoutGrid columns={mainGridColumns}>
                <EGDSLayoutGridItem className="child-container" {...regionWidthToGridMap[width]}>
                  <EGDSSpacing padding={{ block: "twelve" }} margin={{ block: "four" }}>
                    <div>
                      {<RegionChildren templateComponent={templateComponent} blossomComponent={blossomComponent} />}
                    </div>
                  </EGDSSpacing>
                </EGDSLayoutGridItem>
              </EGDSLayoutGrid>
            </EGDSFigure>
          </ViewMedium>
        </Viewport>
      </div>
    );
  }
}

export default FullBackgroundRegion;
